import { IGuidTracking } from './../Model/IGuidTracking';
import { IInteractionTracking } from './../Model/IInteractionTracking';
import { IScenarioTracking } from './../Model/IScenarioTracking';
import { Component, OnInit, ApplicationRef } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { Application } from '@amc-technology/applicationangularframework';
import { Util } from '../Util';
import { bind } from 'bind-decorator';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { DomSanitizer, SafeResourceUrl, TransferState } from '@angular/platform-browser';
import { IInteraction, INTERACTION_STATES, INTERACTION_DIRECTION_TYPES } from '@amc-technology/davinci-api';
import { getCadDisplayConfig } from '../util/DisplayCADInfo';
import { ICadDisplayConfig } from '../Model/ICadDisplayConfig';

@Component({
  selector: 'app-home',
  templateUrl: './home-sap.component.html'
})

export class HomeSapComponent extends Application implements OnInit {
  protected phoneNumberFormat: Object;
  protected quickCommentList: string[];
  protected cadPopKeys: {
    [cadName: string]: { entity: string; field: string };
  };
  UIType: string;
  public ClickToDialBridgeURL: SafeResourceUrl;
  isHttps: boolean;
  protected eventFilter: Object;
  public scenarioMap = new Map();
  screenpopOnAlert: Boolean;
  public clickToDialPhoneReformatMap: Object;
  public transfers = {
    transNums: []
  };
  private mostRecentNum: string;
  private cadDisplayConfig: ICadDisplayConfig;
  protected cadActivityMap: Object;

  public scenarioTracking: {
    [scenarioId: string]: IScenarioTracking
  } = {};

  public guidTracking: {
    [generatedGuid: string]: IGuidTracking
  } = {};
  public delayGuidDelete: number;

  private clickToDialTriggered: boolean;
  private interactionAttributesMapping: Object;
  private lastInteractionTime = Date.now();
  private payloadDelay = 2500;

  constructor(private loggerService: LoggerService, public storageService: StorageService, public sanitizer: DomSanitizer) {
    super(loggerService.logger);
    this.storageService.syncWithLocalStorage();
    this.cadPopKeys = {};
    this.phoneNumberFormat = {};
    this.cadActivityMap = {};
    this.clickToDialPhoneReformatMap = {};
    this.UIType = 'unknown';
    this.isHttps = false;
    this.eventFilter = {};
    this.screenpopOnAlert = true;
    this.clickToDialTriggered = false;
    this.interactionAttributesMapping = {};
  }

  async ngOnInit() {
    try {
      this.loggerService.logger.logDebug('SAP - Home : START : Initializing SAP App');
      await this.loadConfig();
      this.bridgeScripts.push(this.getBridgeURL());
      await super.ngOnInit();

      if (this.appConfig.variables['ClickToDialPhoneReformatMap']) {
        this.clickToDialPhoneReformatMap = this.appConfig.variables['ClickToDialPhoneReformatMap'];
      }

      // TODO: This config is temporary until SAP fixes payload processing
      if (this?.appConfig?.variables['PayloadDelay']) {
        this.payloadDelay = this.appConfig.variables['PayloadDelay'];
      }

      // Hidden config that we only want devs aware of. The variable is for the time in which out guid tracking
      // object deletes a guid post call disconnect. Benefits of this can be after a disposition of a call, a new
      // guid isn't generated and logs can be accurate.
      if (this.appConfig.variables['guidCleanUpTimer']) {
        this.delayGuidDelete = this.appConfig.variables['guidCleanUpTimer'];
      } else {
        this.delayGuidDelete = 125000;
      }

      const storedScenarioTracking = this.storageService.getScenarioTracking();
      const storedGuidTracking = this.storageService.getGuidTracking();

      this.scenarioTracking = storedScenarioTracking ? storedScenarioTracking : this.scenarioTracking;
      this.guidTracking = storedGuidTracking ? storedGuidTracking : this.guidTracking;

      this.bridgeEventsService.subscribe('clickToDial', event => {
        const numberToDial = this.clickToDialFormatPhoneNumber(event.number);
        this.clickToDialTriggered = true;
        api.clickToDial(numberToDial);
      });
      this.bridgeEventsService.subscribe('UIType', event => {
        if (event.UIType === 'html') {
          let url = event.url.replace('?', '');
          url += 'ClickToDialBridge.html';
          this.ClickToDialBridgeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        this.isHttps = event.isHttps;
        this.UIType = event.UIType;
      });
      this.loggerService.logger.logDebug('SAP - Home : Configuration from SAP App : ' + JSON.stringify(this.appConfig));

      const configPhoneFormat = this.appConfig.variables['PhoneNumberFormat'];
      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }

      this.UpdateEventFilterConfig(this.appConfig.variables['EventFilter']);
      this.quickCommentList = <string[]>this.appConfig.variables['QuickComments'];
      const CID = String(this.appConfig.variables['CID']);
      this.bridgeEventsService.sendEvent('setInitialParams', {
        CID: `CID=${CID}`
      });
      api.registerOnLogout(this.removeLocalStorageOnLogout.bind(this));
      this.loggerService.logger.logDebug('SAP - Home : END : Initializing SAP App');
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : Initializing SAP App. Error Information : '
        + JSON.stringify(error));
    }
  }

  protected UpdateEventFilterConfig(eventFilterConfig: any): void {
    try {
      this.loggerService.logger.logDebug('SAP - Home : START : Updating Event Filter Config');
      if (eventFilterConfig) {
        const eventScenarios: string[] = Object.keys(eventFilterConfig);
        for (const scenario of eventScenarios) {
          this.eventFilter[scenario] = [];
          if (eventFilterConfig[scenario]) {
            const eventSubScenarios: string[] = eventFilterConfig[scenario].split('|');
            for (let index = 0; index < eventSubScenarios.length; index++) {
              this.eventFilter[scenario].push(eventSubScenarios[index]);
            }
          }
        }
      }
      this.loggerService.logger.logDebug('SAP - Home : END : Updating Event Filter Config');
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : Updating Event Filter Config. Error Information : ' +
        JSON.stringify(error) + '. Event Filter Config Information : ' + JSON.stringify(eventFilterConfig));
    }
  }

  protected async processConfig(config: api.IAppConfiguration) {
    try {
      this.loggerService.logger.logDebug('SAP - Home : START : Process Config');
      if (
        config['variables']['ScreenpopOnAlert'] !== null &&
        config['variables']['ScreenpopOnAlert'] !== undefined
      ) {
        this.screenpopOnAlert = Boolean(
          config['variables']['ScreenpopOnAlert']
        );
      }
      let cadPopKeys = String(config.variables['InteractionAttributesPop']);
      if (cadPopKeys) {
        if (cadPopKeys[cadPopKeys.length - 1] === ';') {
          cadPopKeys = cadPopKeys.slice(0, -1);
        }
        const keys = cadPopKeys.split(';');
        for (let i = 0; i < keys.length; i++) {
          const keyAttributes = keys[i].split(',');
          if (keyAttributes.length === 2) {
            this.cadPopKeys[keyAttributes[0]] = {
              entity: '',
              field: keyAttributes[1]
            };
          } else {
            this.loggerService.logger.logError('SAP - Home : ERROR : Process Config. Format Error: CadPopKeys should contain 2 comma - separated' +
              ' values, received : ' + keys[i]);
          }
        }
      }

      if (config && config['variables'] && config['variables']['Interaction Attributes Mapping']) {
        this.interactionAttributesMapping = config['variables']['Interaction Attributes Mapping'];
      }

      this.cadDisplayConfig = getCadDisplayConfig(config);
      this.storageService.displayCadData = this.cadDisplayConfig.DisplayCad;
      this.loggerService.logger.logDebug('SAP - Home : END : Process Config');
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : Process Config. Error Information : '
        + JSON.stringify(error) + '. Config Information : ' + JSON.stringify(config));
    }
  }

  protected async removeLocalStorageOnLogout(): Promise<any> {
    this.loggerService.logger.logDebug('SAP - Home : Clearing Local Storage on Log out');
    await this.loggerService.logger.pushLogsAsync();
    return new Promise(() => {
      localStorage.clear();
    });
  }

  protected getUserInfoHandler(): Promise<string> {
    return new Promise(() => Util._SAPWorkTop);
  }

  protected formatPhoneNumber(inputNumber: string, phoneNumberFormat: Object): string {
    try {
      this.loggerService.logger.logInformation('SAP - Home : START : Formatting number. Input Number : ' + inputNumber + ', Phone Number Format : ' +
        JSON.stringify(phoneNumberFormat));
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.loggerService.logger.logInformation('SAP - Home : END : Formatting number. Input Number : ' + inputNumber + ', Output Number : ' +
              outputNumber);
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : Error in formatting number. Please check the configuration. Exception : ' +
        JSON.stringify(error));
    }
    this.loggerService.logger.logInformation('SAP - Home : END : Formatting number. Input Number : ' + inputNumber + ', Output Number : ' + inputNumber);
    return inputNumber;
  }

  formatCrmResults(crmResults: any): api.SearchRecords {
    try {
      this.loggerService.logger.logDebug('SAP - Home : START : Format CRM Results. Information : ' + JSON.stringify(crmResults));
      const ignoreFields = [
        'Name',
        'displayName',
        'object',
        'Id',
        'RecordType'
      ];
      const result = new api.SearchRecords();
      for (const id of Object.keys(crmResults)) {
        let recordItem: api.RecordItem = null;
        if (crmResults[id].Id && crmResults[id].RecordType) {
          recordItem = new api.RecordItem(
            crmResults[id].Id,
            crmResults[id].RecordType,
            crmResults[id].RecordType
          );
        } else if (crmResults[id].object && crmResults[id].displayName) {
          recordItem = new api.RecordItem(
            id,
            crmResults[id].object,
            crmResults[id].displayName
          );
        }
        if (recordItem !== null) {
          if (crmResults[id].Name) {
            if (recordItem.getMetadata().Type === 'Account') {
              recordItem.setAccountName('Name', 'Name', crmResults[id].Name);
            } else if (recordItem.getMetadata().Type === 'Contact') {
              recordItem.setFullName('Name', 'Name', crmResults[id].Name);
            } else {
              recordItem.setField('Name', 'Name', 'Name', crmResults[id].Name);
            }
          }
          for (const fieldName of Object.keys(crmResults[id])) {
            if (ignoreFields.indexOf(fieldName) < 0) {
              recordItem.setField(
                fieldName,
                fieldName,
                fieldName,
                crmResults[id][fieldName]
              );
            }
          }
          result.addSearchRecord(recordItem);
        }
      }
      this.loggerService.logger.logDebug('SAP - Home : END : Format CRM Results');
      return result;
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : Format CRM Results. Error Information : '
        + JSON.stringify(error) + '. CRM Results : ' + JSON.stringify(crmResults));
      return null;
    }
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(this.clickToDialPhoneReformatMap);
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        const inputFormat = configuredInputFormats[i];
        const outputFormat = this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/\(/g) || []).length) !== ((number.match(/\(/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/-/g) || []).length) !== ((number.match(/-/g) || []).length)) {
          continue;
        }
        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber = outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  public newScenarioTracking(interaction: api.IInteraction): void {
    try {
      // Create a blank scenario tracking object and associate it with the scenarioId
      this.scenarioTracking[interaction.scenarioId] = {
        totalScenarioDuration: 0,
        totalScenarioHoldDuration: 0,
        interactions: {}
      };
      // Create a new interaction tracking object, set start date to now.
      // Set end date IF AND ONLY IF interaction is already disconnected
      this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId] = {
        totalInteractionDuration: 0,
        totalInteractionHoldDuration: 0,
        timestamps: {
          start: new Date(),
          end: interaction.state === api.INTERACTION_STATES.Disconnected ? new Date() : undefined
        },
        holds: [],
      };
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : newScenarioTracking. Error Information : '
      + JSON.stringify(error) + '. Scenario Tracking info : ' + JSON.stringify(this.scenarioTracking));
      console.error(error);
    }
  }

  public newGuidTracking(guid: string, interaction: api.IInteraction): void {
    try {
      // Create a blank scenario tracking object and associate it with the scenarioId
      this.guidTracking[guid] = {
        scenarioId: interaction.scenarioId,
        interactionId: interaction.interactionId,
        callState: interaction.state.toString()
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : newGuidTracking. Error Information : '
      + JSON.stringify(error) + '. Guid Tracking info : ' + JSON.stringify(this.guidTracking));
      console.error(error);
    }
  }

  public getTimeInSeconds(timestamps: {start: Date, end: Date}): number {
    try {
      /*
      End time in milliseconds = E
      Start time in milliseconds = S
      Time elapsed in seconds = (E - S) / 1000
      */
      return Math.round((timestamps.end.getTime() - timestamps.start.getTime()) / 1000);
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : getTimeInSeconds. Error Information : '
      + JSON.stringify(error) + '. Timestamp Info : ' + JSON.stringify(timestamps));
      console.error(error);
    }
  }

  // Scenario total duration is the sum of all child interaction durations,
  // Scenario total hold duration is the sum of all child interaction hold durations.
  public updateScenarioTrackingTimes(interaction: api.IInteraction): void {
    try {
      const targetScenario = this.scenarioTracking[interaction.scenarioId];

      // Reset total scenario duration
      targetScenario.totalScenarioDuration = 0;
      // Iterate through all child interactions and aggregate their total duration
      for (const targetInteractionId of Object.keys(targetScenario.interactions)) {
        targetScenario.totalScenarioDuration += targetScenario.interactions[targetInteractionId].totalInteractionDuration;
      }

      // Reset total scenario hold time
      targetScenario.totalScenarioHoldDuration = 0;
      // Iterate through all child interactions and aggregate their total hold duration
      for (const targetInteractionId of Object.keys(targetScenario.interactions)) {
        // Update target interaction hold time
        this.updateInteractionHoldTime(targetScenario.interactions[targetInteractionId]);
        targetScenario.totalScenarioHoldDuration += targetScenario.interactions[targetInteractionId].totalInteractionHoldDuration;
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : updateInteractionTrackingTimes. Error Information : '
      + JSON.stringify(error) + '. Scenario Tracking Info : ' + JSON.stringify(this.scenarioTracking[interaction.scenarioId]));
      console.error(error);
    }
  }

  // This function only adds hold times for holds that are completed. (holds that have a start and end date)
  public updateInteractionHoldTime(interactionTracking: IInteractionTracking): void {
    try {
      // Reset Interaction total hold time
      interactionTracking.totalInteractionHoldDuration = 0;

      // Iterate through all holds and aggregate total hold time
      for (const hold of interactionTracking.holds) {
        if (hold.start && hold.end) {
          // Convert back to date object in case it was stored as string
          hold.start = new Date(hold.start);
          hold.end = new Date(hold.end);
          interactionTracking.totalInteractionHoldDuration += this.getTimeInSeconds(hold);
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : updateInteractionHoldTime. Error Information : '
      + JSON.stringify(error) + '. Interaction Tracking Info : ' + JSON.stringify(interactionTracking));
      console.error(error);
    }
  }

  public updateScenarioCallTimes(interaction: IInteraction): void {
    try {
      this.loggerService.logger.logInformation('SAP - Home : START : updating scenario tracking call times. Information : ' +
      JSON.stringify(this.scenarioTracking[interaction.scenarioId]));

      if (!this.scenarioTracking[interaction.scenarioId]) {
        // If we have a new scenario, create a new scenario tracking object
        this.newScenarioTracking(interaction);

      } else if (!this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId]) {
        // If we have a new interaction with a preexisting scenario, create a new interaction tracking object
        // and add it to the existing scenario tracking object. If the interaction is already disconnected,
        // set the end date.

        this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId] = {
          totalInteractionDuration: 0,
          totalInteractionHoldDuration: 0,
          timestamps: {
            start: new Date(),
            end: interaction.state === api.INTERACTION_STATES.Disconnected ? new Date() : undefined
          },
          holds: []
        };

      } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        // if the interaction exists and has been disconnected, update the end timestamp, the interaction
        // total call time, and the scenario total call time
        const targetInteraction = this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId];

        // Conditionally set end date to close interaction. Only do so if the end date has not been set
        // to avoid duplicate requests.
        if (!targetInteraction.timestamps.end) {
          targetInteraction.timestamps.end = new Date();
        }
        // Convert to Date if saved as string
        targetInteraction.timestamps.start = new Date(targetInteraction.timestamps.start);
        targetInteraction.timestamps.end = new Date(targetInteraction.timestamps.end);
        // Calculate total call time in seconds and set total interaction duration
        targetInteraction.totalInteractionDuration = this.getTimeInSeconds(targetInteraction.timestamps);
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : updateScenarioTracking. Error Information : '
      + JSON.stringify(error) + '. Scenario Info : ' + JSON.stringify(this.scenarioTracking[interaction.scenarioId]));
      console.error(error);
    }
  }

  public updateScenarioHoldTimes(interaction: IInteraction): void {
    if (interaction.state === api.INTERACTION_STATES.OnHold) {
      const targetScenario = this.scenarioTracking[interaction.scenarioId];

      const amountOfHolds = targetScenario.interactions[interaction.interactionId].holds.length;

      // Only add a new open hold if the last hold has not been closed to avoid duplicate requests
      if (amountOfHolds === 0 || targetScenario.interactions[interaction.interactionId].holds[amountOfHolds - 1].end) {
        // If the interaction exists, and it has been put on hold, add a new hold to the interaction tracking
        targetScenario.interactions[interaction.interactionId].holds.push({
          start: new Date(),
          end: undefined
        });
      }
    } else {
      // If the interaction exists, and is not on hold, check if a previous hold has been placed but has not been closed.
      // If so, close the hold, otherwise do nothing.
      const targetInteraction = this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId];

      const amountOfHolds = targetInteraction.holds.length;

      if (amountOfHolds > 0 && !targetInteraction.holds[amountOfHolds - 1].end) {
        targetInteraction.holds[amountOfHolds - 1].end = new Date();
      }
    }
  }

  public async onInteraction(interaction: api.IInteraction): Promise<api.SearchRecords> {
    try {
      this.loggerService.logger.logInformation('SAP - Home : START : Received Interaction. Information : ' + JSON.stringify(interaction));
      const scenarioId = interaction.scenarioId;
      if (interaction.details && interaction.details.fields && interaction.details.fields.Phone
        && interaction.details.fields.Phone.Value) {
        interaction.details.fields.Phone.Value = this.formatPhoneNumber(interaction.details.fields.Phone.Value, this.phoneNumberFormat);
      }

      // Update scenario tracking times as interaction event comes in
      // this.updateScenarioTracking(interaction);
      this.updateScenarioCallTimes(interaction);
      this.updateScenarioHoldTimes(interaction);
      this.updateScenarioTrackingTimes(interaction);
      this.storageService.updateCadFields(
        interaction,
        this.cadActivityMap,
        this.cadDisplayConfig

      );


      this.storageService.setCurrentScenarioId(scenarioId);


      if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        this.loggerService.logger.logInformation('SAP - Home : Removing interaction from interaction events. Scenario ID : ' + interaction.scenarioId);
        this.storageService.removeInteractionEvent(interaction.scenarioId);
        delete this.storageService.displayCadList[scenarioId];
        delete this.storageService.scenarioToCADMap[scenarioId];
        this.loggerService.logger.logInformation('SAP - Home : Removed interaction from interaction events. Scenario ID : ' + interaction.scenarioId);
        this.storageService.setCurrentScenarioId(null);
        this.storageService.currentScenarioId = null;
        this.storageService.displayCadList = {};
        this.storageService.scenarioToCADMap = {};
      }
      this.sendInteraction(interaction);
      this.loggerService.logger.logInformation('SAP - Home : END : Received Interaction. Information : ' + JSON.stringify(interaction));
    } catch (e) {
      const msg = `Error in onInteraction! Exception details: ${e.message}`;
      this.loggerService.logger.logError(msg);
      throw msg;
    }
    return;
  }

  private async sendInteraction(interaction: api.IInteraction): Promise<void> {
    try {
      this.loggerService.logger.logInformation('SAP - Home : START : SendInteraction : ' + JSON.stringify(interaction));
      let transferCall = false;
      const details = api.RecordItem.fromJSON(interaction.details);
      let truncatedInteractionId;
      let isGuidCreatedAlready = false;

      // Create GUID for a call with matching interaction and scenario ids if one does not already exist
      if (interaction.interactionId &&
          interaction.scenarioId &&
          interaction.interactionId === interaction.scenarioId
      ) {
        for(let key in this.guidTracking) {
          // If the guid is already created for the call we set isGuidCreatedAlready var to true so we do not
          // create a duplicate GUID for the same call
          if(interaction.interactionId === this.guidTracking[key].interactionId &&
             interaction.scenarioId === this.guidTracking[key].scenarioId &&
             (interaction.state >= parseInt(this.guidTracking[key].callState) ||
              parseInt(this.guidTracking[key].callState) === api.INTERACTION_STATES.OnHold ||
              parseInt(this.guidTracking[key].callState) === api.INTERACTION_STATES.Initiated)
            ) {
            isGuidCreatedAlready = true;
          }
        }
        if (isGuidCreatedAlready === false) {
          this.loggerService.logger.logInformation('SAP - Home : SendInteraction CTI Interaction ID Before Generated Interaction ID: ' + JSON.stringify(interaction.interactionId));
          truncatedInteractionId = this.randomThirtyGUID();
          this.newGuidTracking(truncatedInteractionId, interaction);
          this.loggerService.logger.logInformation('SAP - Home : SendInteraction Generated Unique InteractionID : ' + JSON.stringify(truncatedInteractionId));
        }
      }

      /* If a guid was already created for the call, determine which guid stored is associated with the call.
       Keep in mind if the call is active (alerting, connected, onhold, or initiated) this means this is the
       active interaction. */
      if (interaction.interactionId &&
          interaction.scenarioId &&
          interaction.interactionId === interaction.scenarioId &&
          isGuidCreatedAlready === true
          ) {
        for(let key in this.guidTracking) {
          if(interaction.interactionId === this.guidTracking[key].interactionId &&
             interaction.scenarioId === this.guidTracking[key].scenarioId
            ) {
              if (parseInt(this.guidTracking[key].callState) === interaction.state) {
                truncatedInteractionId = key;
                // Key exists in guid tracking, but the call state does not match.
                // Need to check if the call state stored locally is behind the interaction state, if so change it
                // OnHold enum is 3 so if the call state stored localy is onHold it can change and go down in value
                // based on enum position
              } else if (parseInt(this.guidTracking[key].callState) < interaction.state ||
                          (parseInt(this.guidTracking[key].callState) !== interaction.state &&
                          parseInt(this.guidTracking[key].callState) === api.INTERACTION_STATES.OnHold) ||
                          (parseInt(this.guidTracking[key].callState) !== interaction.state &&
                          parseInt(this.guidTracking[key].callState) === api.INTERACTION_STATES.Initiated)
              ) {
                truncatedInteractionId = key;
                this.guidTracking[key].callState = interaction.state.toString();
                if (this.guidTracking[key].callState === api.INTERACTION_STATES.Disconnected.toString()) {
                  this.deleteGuids(interaction);
                }
              }
          }
        }
      } else if (interaction.interactionId &&
        interaction.scenarioId &&
        interaction.interactionId !== interaction.scenarioId
        ) {
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction Pre-Hex Encoding of Interaction ID : ' + interaction.interactionId);
        // Encoding to hex in order to remove an non-alphanumeric characters in the interaction id
        truncatedInteractionId = Buffer.from(interaction.interactionId).toString('hex').toUpperCase();
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction Hex Encoding of Interaction ID : ' + truncatedInteractionId);
      }

      const event = {
        id: truncatedInteractionId,
        type: details.getMetadata().Type,
        phoneNumbers: [],
        cadFields: []
      };

      // prepend the device
      event.cadFields.push({
        entity: '',
        field: 'CID',
        value: 'BCM1234'
      });

      if (interaction.direction === api.INTERACTION_DIRECTION_TYPES.Internal) {
        this.storageService.removeInteractionEvent(interaction.scenarioId);
        transferCall = true;
      }

      if (interaction.details) {
        if (interaction.details.getMetadata()) {
          event['type'] = interaction.details.getMetadata().Type;
          this.loggerService.logger.logInformation('SAP - Home : SendInteraction MetaData : ' + JSON.stringify(interaction.details.getMetadata()));
        }

        if (interaction.details.getPhone()) {
          if (transferCall && this.mostRecentNum && this.mostRecentNum !== '') {
            event.phoneNumbers.push(this.mostRecentNum);
          } else {
            event.phoneNumbers.push(interaction.details.getPhone().Value);
          }
        }
        if (interaction.details.getOtherPhone()) {
          event.phoneNumbers.push(interaction.details.getOtherPhone().Value);
        }
        if (interaction.details.getHomePhone()) {
          event.phoneNumbers.push(interaction.details.getHomePhone().Value);
        }
        if (interaction.details.getMobile()) {
          event.phoneNumbers.push(interaction.details.getMobile().Value);
        }

        // Add call and hold tracking data to interaction on disconnect
        // This data will only get pushed to SAP if it is configured to do so
        if (interaction.state === INTERACTION_STATES.Disconnected) {
          interaction.details.fields['TotalHoldDuration'] = {
            DevName: '',
            DisplayName: 'Total Hold Duration',
            Value: this.scenarioTracking[interaction.scenarioId].totalScenarioDuration
            // .interactions[interaction.interactionId].totalInteractionHoldDuration
          };

          interaction.details.fields['NumberOfHolds'] = {
            DevName: '',
            DisplayName: 'Number of Holds',
            Value: this.scenarioTracking[interaction.scenarioId].interactions[interaction.interactionId].holds.length
          };

          interaction.details.fields['TotalCallDuration'] = {
            DevName: '',
            DisplayName: 'Total Call Duration',
            Value: this.scenarioTracking[interaction.scenarioId].totalScenarioHoldDuration
            // .interactions[interaction.interactionId].totalInteractionDuration
          };
        }

        // This specifies any 'cad' the channel provides that we can screenpop on
        if (this.cadPopKeys) {
          for (const key of Object.keys(this.cadPopKeys)) {
            for (const interactionField of this.cadPopKeys[key].field.split('|')) {
              if (details.getField(interactionField)) {
                event.cadFields.push({
                  entity: this.cadPopKeys[key].entity,
                  field: key,
                  value: details.getField(interactionField).Value
                });
                break;
              }
            }
          }
        }
      }

      // SAP supports has a limit on the number of characters for the ExternalReferenceID and ExternalOriginalReferenceID
      // currently sending the last 30 characters to SAP
      if (truncatedInteractionId && truncatedInteractionId.length > 30) {
        truncatedInteractionId = truncatedInteractionId.substr(truncatedInteractionId.length - 30);
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction : Interaction ID in Payload : ' + truncatedInteractionId);
      }

      // determine ExternalReferenceID
      event.cadFields.push({
        entity: '',
        field: 'ExternalReferenceID',
        value: truncatedInteractionId
      });

      let truncatedScenarioId;
      // Encoding to hex in order to remove an non-alphanumeric characters in the interaction id
      if (interaction.scenarioId) {
        this.loggerService.logger.logDebug('SAP - Home : SendInteraction Pre-Hex Encoding of Scenario ID : ' + interaction.scenarioId);
        truncatedScenarioId = Buffer.from(interaction.scenarioId).toString('hex').toUpperCase();
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction Hex Encoding of Scenario ID : ' + truncatedScenarioId);
      }
      if (truncatedScenarioId && truncatedScenarioId.length > 30) {
        truncatedScenarioId = truncatedScenarioId.substr(truncatedScenarioId.length - 30);
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction : Scenario ID in Payload : ' + truncatedScenarioId);
      }

      event.cadFields.push({
        entity: '',
        field: 'ExternalOriginalReferenceID',
        value: truncatedScenarioId
      });

      if (this.interactionAttributesMapping) {
        event['otherFields'] = {};

        for (const key of Object.keys(this.interactionAttributesMapping)) {
          if (details.getField(key)) {
            event['otherFields'][key] = {
              field: key,
              value: details.getField(key).Value
            };
          }
        }

        if (Object.keys(event['otherFields']).length === 0) {
          delete event['otherFields'];
        }
      }

      // determine action_code
      let action_code = '';
      let type = '';

      if // state connected
        (interaction.state === api.INTERACTION_STATES.Alerting || interaction.state === api.INTERACTION_STATES.Initiated) {
        if (interaction.channelType === api.CHANNEL_TYPES.Telephony) {
          action_code = 'CALL_ALLOCATED';
          type = 'CALL';
        } else if (interaction.channelType === api.CHANNEL_TYPES.Chat || interaction.channelType === undefined
          || interaction.channelType === api.CHANNEL_TYPES.SMS) {
          action_code = 'CHAT_ALLOCATED';
          type = 'CHAT';
        }
      } else if (interaction.state === api.INTERACTION_STATES.Connected) {
        if (interaction.channelType === api.CHANNEL_TYPES.Telephony) {
          action_code = 'CALL_CONNECTED';
          type = 'CALL';
        } else if (interaction.channelType === api.CHANNEL_TYPES.Chat || interaction.channelType === undefined
          || interaction.channelType === api.CHANNEL_TYPES.SMS) {
          action_code = 'CHAT_CONNECTED';
          type = 'CHAT';
        }
      } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        if (interaction.channelType === api.CHANNEL_TYPES.Telephony) {
          action_code = 'CALL_DISCONNECTED';
          type = 'CALL';
        } else if (interaction.channelType === api.CHANNEL_TYPES.Chat || interaction.channelType === undefined
          || interaction.channelType === api.CHANNEL_TYPES.SMS) {
          action_code = 'CHAT_DISCONNECTED';
          type = 'CHAT';
        }
      }

      event.cadFields.push({
        entity: '',
        field: 'action_code',
        value: action_code
      });

      event.cadFields.push({
        entity: '',
        field: 'Event',
        value: action_code
      });

      event.cadFields.push({
        entity: '',
        field: 'Type',
        value: type
      });

      // When call is diconnected, in order to end the call time in phone call activity, event type must be Update Activity
      if (interaction.state === INTERACTION_STATES.Disconnected) {
        event.cadFields.push({
          entity: '',
          field: 'EventType',
          value: 'UPDATEACTIVITY'
        });
      } else {
        // All Inbound calls will have the Transfer event type payload in order to satisfy contextual transfer of the ticket id and notes when
        // a warm transfer occurs. For the time being, this is the desired solution.
        event.cadFields.push({
          entity: '',
          field: 'EventType',
          value: (interaction.direction === api.INTERACTION_DIRECTION_TYPES.Inbound ? 'TRANSFER' :
          (interaction.direction === api.INTERACTION_DIRECTION_TYPES.Outbound ? 'OUTBOUND' : 'TRANSFER'))
        });
      }

      if (interaction.details.getPhone()) {
        this.mostRecentNum = interaction.details.getPhone().Value;
      }

      // Sends all events as a NOTIFY event if not a call allocated event
    let newScenario = true;
    const curScenario = this.scenarioMap.get(interaction.scenarioId);

      if (curScenario && curScenario.includes(interaction.interactionId)) {
        if(interaction.state === api.INTERACTION_STATES.Disconnected) {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('END')
          });
        } else {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('NOTIFY')
          });
        }
        newScenario = false;
      } else if (curScenario) {
        if(interaction.state === api.INTERACTION_STATES.Disconnected) {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('END')
          });
        } else {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('NOTIFY')
          });
        }
        curScenario.push(interaction.interactionId);
        newScenario = false;
      }

      if (newScenario === true && this.screenpopOnAlert === true) {
        if (interaction.state !== api.INTERACTION_STATES.Disconnected) {
      this.scenarioMap.set(interaction.scenarioId, [interaction.interactionId]);

        if (this.clickToDialTriggered) {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('NOTIFY')
          });
          this.clickToDialTriggered = false;
        }

        } else {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('END')
          });
        }
      } else if (newScenario === true && this.screenpopOnAlert === false) {
        if (interaction.state === api.INTERACTION_STATES.Alerting) {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('NOTIFY')
          });
        } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
          event.cadFields.push({
            entity: '',
            field: 'Action',
            value: ('END')
          });
        } else {
          this.scenarioMap.set(interaction.scenarioId, [interaction.interactionId]);

          if (this.clickToDialTriggered) {
            event.cadFields.push({
              entity: '',
              field: 'Action',
              value: ('NOTIFY')
            });
            this.clickToDialTriggered = false;
          }

        }
      }

      if (interaction.state === api.INTERACTION_STATES.Disconnected && newScenario === false) {
        this.scenarioMap.delete(interaction.scenarioId);
      }

      this.storageService.setInteractionEvent(interaction.scenarioId);
      this.storageService.setScenarioTracking(this.scenarioTracking);
      this.storageService.setGuidTracking(this.guidTracking);
      this.loggerService.logger.logInformation('SAP - Home : SendInteraction. Sending Event to Bridge : ' + JSON.stringify(interaction));

      const timeNow = Date.now();

      if (timeNow - this.lastInteractionTime < this.payloadDelay) {
        this.loggerService.logger.logInformation(`SAP - Home : SendInteraction. Deferred event to Bridge by ${this.payloadDelay - (timeNow - this.lastInteractionTime)}ms : ${JSON.stringify(interaction)}`);

        setTimeout(() => {
          this.bridgeEventsService.sendEvent('screenPop', event);
          this.loggerService.logger.logInformation('SAP - Home : SendInteraction. Deferred event sent to Bridge : ' + JSON.stringify(interaction));
        }, this.payloadDelay - (timeNow - this.lastInteractionTime))

        this.lastInteractionTime += this.payloadDelay;
      } else {
        this.lastInteractionTime = Date.now();
        this.bridgeEventsService.sendEvent('screenPop', event);
        this.loggerService.logger.logInformation('SAP - Home : SendInteraction. Sent Event to Bridge : ' + JSON.stringify(interaction));
      }

      this.loggerService.logger.logDebug('SAP - Home : END : SendInteraction');
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : SendInteraction. Error Information : '
        + JSON.stringify(error) + '. Interaction Info : ' + JSON.stringify(interaction));
    }
  }

  protected isToolbarVisible(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  protected saveActivity(activity: api.IActivity): Promise<string> {
    throw new Error('Method not implemented.');
  }

  protected getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Method not implemented.');
  }

  // Generate a random alphanumeric GUID of length 30.
  // Current Usage: If the interaction id and scenario id are the same, generate a new
  // interaction id so that if a warm transfer occurs, the interaction id for the second agent
  // will not match the first agent. In order for a warm transfer to work, the External ID needs to
  // be different, but the Original External ID (Agent 1's interaction id) must match in all
  // following calls
  public randomThirtyGUID() {
    try {
      let randomValueArray = crypto.getRandomValues(new Uint8Array(30));
      let generateGUIDFromArray: any = randomValueArray.reduce((a: any, c: any) => {return a + c.toString(36)});
      if (generateGUIDFromArray && generateGUIDFromArray.length > 30) {
        generateGUIDFromArray = generateGUIDFromArray.substr(generateGUIDFromArray.length - 30);
      }
      let generateGUIDFromArrayUpper = generateGUIDFromArray.toUpperCase();
      return generateGUIDFromArrayUpper;
    } catch (error) {
      this.loggerService.logger.logError('SAP - Home : ERROR : randomThirtyGUID. Error Information : ' + JSON.stringify(error));
    }
  }

  public async deleteGuids(interaction: IInteraction) {
    this.loggerService.logger.logDebug('SAP - Home : Debug : deleteGuids. Start : Interaction : ' + JSON.stringify(interaction));
    await new Promise<void>((resolve, reject) => {
      this.loggerService.logger.logDebug('SAP - Home : Debug : deleteGuids : Delay removing activity : Scenario ID : ' + JSON.stringify(interaction.scenarioId));
      const timer = setTimeout(() => {
        resolve();
      }, this.delayGuidDelete);
    });
    for (let guid in this.guidTracking) {
      if (this.guidTracking[guid].scenarioId === interaction.scenarioId &&
          this.guidTracking[guid].callState === api.INTERACTION_STATES.Disconnected.toString()) {
            delete this.guidTracking[guid];
            break;
          }
    }
    this.storageService.setGuidTracking(this.guidTracking);
  }
}

